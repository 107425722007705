.icon {
  opacity: 0;
  transition: opacity ease 300ms;
	overflow: hidden;
}

.icon.show {
  opacity: 1;
  width: 20px;
  height: 20px;
}

.icon:hover {
  opacity: 0.5;
}


.preview {
	width: 0%;
	height: 0%;
	opacity: 0;
	transition: opacity ease-out 300ms;
	overflow: hidden;
}

.preview.show {
	width: 12%;
	height: 10%;
	opacity: 1;
}

.preview:hover {
	width: 12.5%;
	height: 10.5%;
}
